@tailwind base;
@tailwind components;
@tailwind utilities;



.container {
  max-width: 100vw;
  width: 100%;
}



.overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
  border-radius: 8px;
  /* border: 1px solid rgba(255, 255, 255, 0.2); */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  visibility: hidden;
  

}
.card-container:hover .overlay {
  visibility: visible;
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}




@keyframes scrolling {
  0% { transform: translateX(0); }
  100% { transform: translateX(-100%); }
}

.box-1 {
  border-radius: 0px 200px;
}
.box-1-1 {
  border-radius: 200px 200px 0px 200px;
  background: url("./assets/hands.jpg") rgb(10, 164, 59) 50% / cover no-repeat;
}
.box-2 {
  border-radius: 0px 0px 200px 0px;
  background: url("./assets/img-4.jpg") rgb(0, 34, 51) 50% / cover no-repeat;
}
.box-4 {
  border-radius: 400px 200px 200px 0px;
  background: url("./assets/lekki-bridge.jpg") rgb(10, 164, 59) 50% / cover
    no-repeat;
  /* flip horizontal */
  transform: scaleX(-1);
}

.about {
  background: linear-gradient(1deg, #f3f7f7 0.73%, #fff 99.45%);
  position: relative;
}
.about::after {
  content: "";
  position: absolute;
  top: 0;
  right: 20%;
  width: 500px;

  height: calc(100% - 100px);
  border-radius: 751.322px;
  background: linear-gradient(117deg, #fdf8e3 13.39%, #72e79fad 97.46%);
  filter: blur(100px);
  z-index: 0;
}
.about3 {
  background: linear-gradient(1deg, #fff 0.73%, #f3f7f7 99.45%);
  position: relative;
}
.about3::after {
  content: "";
  position: absolute;
  top: 0;
  right: 20%;
  width: 500px;

  height: calc(100% - 100px);
  border-radius: 751.322px;
  background: linear-gradient(117deg, #fdf8e3 13.39%, #72e79fad 97.46%);
  filter: blur(100px);
  z-index: 0;
}
.about-box-1 {
  z-index: 1;
  background: url("./assets/img-1.jpg");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  width: 550px;
  height: 450px;
  border-radius: 12px;
}
.about-box-2 {
  z-index: 1;
  background: url("./assets/img-2.jpg");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  width: 500px;
  height: 350px;
  border-radius: 10px 200px 200px 200px;
}
/* .features{
  background: linear-gradient(1deg, #ffffff 0.73%, #ffffff 45%);
  position: relative;
}
.features::after {
  content: "";
  position: absolute;
  top: 0;
  left: 10%;
  width: 500px;
  height: calc(100% - 100px);
  border-radius: 751.322px;
  background: linear-gradient(117deg, #fdf8e3 13.39%, #d2f0dead 97.46%);
  filter: blur(100px);
  z-index: 0;
} */

.features {
  background-size: 200% 200%;
  background-image: linear-gradient(
    45deg,
    #fdf8e3,
    #d2f0dead,
    #ededf847,
    #d2f0dead,
    #fdf8e3
  );
  animation: Gradient 5s ease infinite;
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media (max-width < 768px) {
  .about::after {
    display: none;
  }
  .about-box-1 {
    display: none;
  }
  .about-box-2 {
    display: none;
  }
  .features::after {
    display: none;
  }
}

@media (width < 768px) {
  .about::after {
    display: none;
  }
  .about-box-1 {
    display: none;
  }
  .about-box-2 {
    display: none;
  }
  .features::after {
    display: none;
  }
}

.navbar {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0; /* Distance from the top of the viewport */
  z-index: 1000; /* Ensure it sits on top of other elements */
  transition: all 0.3s ease-in-out;
}

.scrolledClass {
  background-color: #f0f0f0cb;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}

/* form container */
.form-container {
  position: relative;
  background: #e9fbf0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/*  create a big circle at the top right corner */
.form-container::after {
  content: "";
  position: absolute;
  top: -10%;
  right: -10%;
  width: 600px;
  height: 600px;
  border-radius: 50%;
  background: #23d26b;
  /* filter: blur(100px); */
  z-index: 1;
}
.qr-container2 {
  position: relative;
  background: #e9fbf0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
/*  create a big circle at the top right corner */
.qr-container2::after {
  content: "";
  position: absolute;
  top: -10%;
  right: -10%;
  width: 600px;
  height: 600px;
  border-radius: 50%;
  background: #23d26b;
  /* filter: blur(100px); */
  z-index: 1;
}

.step-1-container {
  width: 80%;
  margin: 30px auto;
  min-height: 80vh;
  height: 100%;
  background: #fff;
  z-index: 2;
  position: relative;
  border-radius: 10px;
  background-image: url("./assets/form-bg.png");
  background-size: 100% 100%;
  background-position: right bottom;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
 /* gap: 20px; */
  padding: 20px;

}
.step-1-submit {
  width: 80%;
  margin: 0 auto;
  min-height: 80vh;
  height: 100%;
  background: #fff;
  z-index: 2;
  position: relative;
  border-radius: 10px;
  background-image: url("./assets/form-bg.png");
  background-size: 80% 100%;
  background-position: right bottom;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 /* gap: 20px; */
  padding: 20px;
}
.step-1-submit2 {
  width: 80%;
  margin: 0 auto;
  min-height: 100vh;
  height: 100%;
  background: #fff;
  z-index: 2;
  position: relative;
  border-radius: 10px;
  background-image: url("./assets/form-bg.png");
  background-size: 80% 100%;
  background-position: right bottom;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 /* gap: 20px; */
  padding: 20px;
}
.qr-bg {
  /* width: 100%; */
  margin: 0 auto;
  /* min-height: 100vh; */
  /* height: 100%; */
  background: #fff;
  z-index: 2;
  position: relative;
  border-radius: 10px;
  background-image: url("./assets/form-bg.png");
  background-size: 80% 100%;
  background-position: right bottom;
  background-repeat: no-repeat;
}
.submit-content{
  height: 100%;
  display: flex;

}
.step-2-container {
  width: 80%;
  margin: 30px auto;
  min-height: 100%;
  height: 100%;
  background: #fff;
  z-index: 2;
  position: relative;
  border-radius: 10px;
  background-image: url("./assets/step-2-form.png");
  background-size: 50% 70%;
  background-position: left bottom;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
 /* gap: 20px; */
  padding: 20px;
}
.form-content{
  width: 90%;
  margin: 0 auto;
  margin-top: 20px ;
}
.top {
  width: 80%;
  margin: 10px auto;
  text-align: center;
  position: relative;
}

#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  counter-reset: step;
}
#progressbar li {
  list-style-type: none;
  color: #a4a4a4;
  font-size: 14px;
  width: 33.33%;
  float: left;
  position: relative;
}
#progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 40px;
  height: 40px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #fff;
  background: #a4a4a4;
  border-radius: 50%;
  margin: 0 auto 5px auto;
}
#progressbar li:after {
  content: "";
  width: 100%;
  height: 2px;
  background: #a4a4a4;
  position: absolute;
  left: -50%;
  top: 20px;
  z-index: -1;
}
#progressbar li:first-child:after {
  content: none;
}
#progressbar li.active {
  color: #23d26b;
}
#progressbar li.active:before,
#progressbar li.active:after {
  background: #23d26b;
  color: white;
}


.button-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligns items to the start of the container (left) */
  margin-left: 60px ;
}

.button-container .next-btn {
  margin: 10px 0;
  padding: 10px 50px;
  border: none;
  border-radius: 5px;
  background: #23d26b;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.button-container .next-btn:hover {
  background: #1aae5b;
}

.button-container .prev-btn {
  margin: 10px 0;
 height: max-content;
   padding: 10px 50px;
  border: 2px solid #23d26b;
  border-radius: 5px;
  background: #fff;
  color: #23d26b;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}



.button-container .prev-btn:hover {
  background: #23d26b;
  color: white;
}
.error-message {
  color: red;
  animation: slideIn 0.3s ease-out;
}

@keyframes slideIn {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}


.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  width: 80%;
  max-width: 500px;
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;
  gap: 20px;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 20px;
}

.modal-actions button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.modal-actions button:nth-child(1) {
  background: #EF4B32;
  color: white;
}

.modal-actions button:nth-child(2) {
  background: #23d26b;
  color: white;
}



.checkmark {
  display: block;
  width: 20vw;
  height: 20vw;
  color: #23d26b; /* SVG path use currentColor to inherit this */
}
.checkmark2 {
  display: block;
  width: 20vw;
  height: 20vw;
  color: #23d26b; /* SVG path use currentColor to inherit this */
}

.circle {
  stroke-dasharray: 76;
  stroke-dashoffset: 76;
  animation: draw 1s forwards;
}

.tick {
  stroke-dasharray: 18;
  stroke-dashoffset: 18;
  animation: draw 1s forwards 1s;
}

@keyframes draw { 
  to { stroke-dashoffset: 0 } 
}

.input-field:nth-child(1) {
  animation-delay: 0.1s;
}

.input-field:nth-child(2) {
  animation-delay: 0.2s;
}

.input-field:nth-child(3) {
  animation-delay: 0.3s;
}

.input-field {
  animation: slideInFromBottom 0.5s ease-out forwards;
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}


/* generate media query for the form */

@media (max-width: 768px) {
  .step-1-container {
    width: 100%;
    margin: 30px auto;
    padding: 10px;
    background-size: 100% 80%;
  }
  .step-2-container {
    width: 100%;
    margin: 30px auto;
    padding: 10px;
  }
  .form-content {
    width: 100%;
    margin: 20px auto;
  }
  .button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
  }
  .button-container .next-btn {
    margin: 10px 0;
  }
  .button-container .prev-btn {
    margin: 10px 0;
  }
  .error-message {
    font-size: 14px;
  }
  .modal-content {
    height: 50%;
  }
  .modal-actions {
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .modal-actions button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  .modal-actions button:nth-child(1) {
    background: #EF4B32;
    color: white;
  }
  .modal-actions button:nth-child(2) {
    background: #23d26b;
    color: white;
  }

  .top {
    width: 100%;
  }
  .container-grid{
    width: 100%;
  }

  .modal-overlay {
    position: fixed;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 4px;
    width: 80%;
    max-width: 500px;
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .modal-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;
    gap: 20px;
  }
  .modal-actions button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }

  .modal-actions button:nth-child(1) {
    background: #EF4B32;
    color: white;
  }

  .modal-actions button:nth-child(2) {
    background: #23d26b;
    color: white;
  }
  .step-1-submit {
    width: 100%;
    margin: 0 auto;
    padding: 10px;
    text-align: center;
  }
  .step-1-submit2 {
    width: 100%;
    height: 100vh;
    margin: 0 auto;
    padding: 10px;
    text-align: center;
  }
  .qr-bg {
    width: 95%;
    margin: 0 auto;
    /* min-height: 100vh; */
    /* height: 100%; */
    background: #fff;
    z-index: 2;
    position: relative;
    border-radius: 10px;
    background-image: url("./assets/form-bg.png");
    background-size: contain;
    background-position: right bottom;
    background-repeat: no-repeat;
  }
  .checkmark{
    width: 50vw;
    height: 50vw;
  }

  .circle {
    stroke-dasharray: 76;
    stroke-dashoffset: 76;
    animation: draw 1s forwards;
  }
  .tick {
    stroke-dasharray: 18;
    stroke-dashoffset: 18;
    animation: draw 1s forwards 1s;
  }
  @keyframes draw {
    to {
      stroke-dashoffset: 0;
    }
  }

  .step-1-submit2 {
    width: 100%;
    margin: 0 auto;
    padding: 10px;
    text-align: center;
  }
}


#burst-12 {
      background: red;
      width: 70px;
      height: 70px;
      text-align: center;
      color: white;
      z-index:1 ;
    }
    #burst-12:before,
    #burst-12:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 70px;
      width: 70px;
      background: red;
    }
    #burst-12:before {
      transform: rotate(30deg);
    }
    #burst-12:after {
      transform: rotate(60deg);
    }

  #burst-12 p{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }

  .activeClass{
    color: #23d26b;
   
  }

  .container-grid {
    position: relative;
    width: 80%;
    height: 80vh;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 2rem;
  }
  
  .container-grid .motion-img {
    width: 100%;
    height: 100%;
  }
  
  .container-grid > div {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
  }
  
  .container-grid button {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    border-radius: 50%; 
    height: 40px;
    width: 40px;
    display: flex; 
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .container-grid button:disabled {
    background-color: rgba(0, 0, 0, 0.2);
    cursor: not-allowed;
  }
  
  .container-grid button:hover:not(:disabled) {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .container-grid button svg {
    fill: white; 
    width: 20px;
    height: 20px;
  }

  .session-banner::before {
    background: linear-gradient(180deg, #252525 0.73%, #1b1b1b 99.45%);
    position: relative;
    z-index: 10;
  }


  .container4{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.507);
    backdrop-filter: blur(10px);
    position: fixed;
    inset: 0;
    z-index: 1000;
  }
  
  .tree {
    position: relative;
    width: 50px;
    height: 50px;
    transform-style: preserve-3d;
    transform: rotateX(-20deg) rotateY(30deg);
    animation: treeAnimate 5s linear infinite;
  }
  
  @keyframes treeAnimate {
    0% {
      transform: rotateX(-20deg) rotateY(360deg);
    }
  
    100% {
      transform: rotateX(-20deg) rotateY(0deg);
    }
  }
  
  .tree div {
    position: absolute;
    top: -50px;
    left: 0;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transform: translateY(calc(25px * var(--x))) translateZ(0px);
  }
  
  .tree div.branch span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #69c069, #77dd77);
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    border-bottom: 5px solid #00000019;
    transform-origin: bottom;
    transform: rotateY(calc(90deg * var(--i))) rotateX(30deg) translateZ(28.5px);
  }
  
  .tree div.stem span {
    position: absolute;
    top: 110px;
   /* updated top value */
    left: calc(50% - 7.5px);
    width: 15px;
    height: 50%;
    background: linear-gradient(90deg, #bb4622, #df7214);
    border-bottom: 5px solid #00000019;
    transform-origin: bottom;
    transform: rotateY(calc(90deg * var(--i))) translateZ(7.5px);
  }
  
  .shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    filter: blur(20px);
    transform-style: preserve-3d;
    transform: rotateX(90deg) translateZ(-65px);
  }


  /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
  

@media screen and (max-width: 768px) {
    .container-grid{
      width: 100%;
    }
}










/* qr code */

.qr-reader {
  width: 430px;
  height: 100vh;
  margin: 0 auto;
  position: relative;
}

.qr-reader video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.qr-reader .qr-box {
  width: 100% !important;
  left: 0 !important;
}

.qr-reader .qr-frame {
  position: absolute;
  fill: none;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

/* Media Queries for mobile screens */
@media (max-width: 426px) {
  .qr-reader {
    width: 100%;
  }
}